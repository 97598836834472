













































































































































































































































import { Component, Vue, Prop  } from "vue-property-decorator";
import { mapGetters } from "vuex";
import axios from "axios";
import { Plotly } from 'vue-plotly';

import _ from "lodash";


axios.defaults.baseURL = process.env.BASE_URL;

@Component({
  computed: mapGetters(["getUser"]),
  components: { Plotly}
})


export default class DeSI_ACTIV_Scores extends Vue{

    DeSIACTIVqId = "";
    infomsg = "";
    getUser: any;
    referrer: any = {};
    baseurl = process.env.BASE_URL;

    scores: any = {};

    activities_R: Array<number> = [];
    activities_Theta: Array<String> = [];

    secteurs_R: Array<number> = [];
    secteurs_Theta: Array<String> = [];

    sortedSecteurs: any = {};
    sortedActivities: any = {};


    fetchData() {
        return axios
        .get("/api/depouillateDeSIACTIV", {params: {id: this.DeSIACTIVqId}})
        .then(ans => {
            this.infomsg = "";
            this.scores = ans.data;

            for (let key in this.scores.activities_values) {
                this.activities_R.push(this.scores.activities_values[key]['percent'] * 100);
                this.activities_Theta.push(this.scores.activities_values[key]['activity']);
            }

            for (let key in this.scores.secteurs_values) {
                console.log()
                this.secteurs_R.push(this.scores.secteurs_values[key]['percent'] * 100);
                this.secteurs_Theta.push(this.scores.secteurs_values[key]['secteur']);
            }
            
            this.sortedSecteurs = _.sortBy(this.scores.secteurs_values, [e=>1-e['percent'], e => e['secteur']]);
            this.sortedActivities = _.sortBy(this.scores.activities_values, [e=>1-e['percent'], e => e['activity']]);

        })
        .catch(err => {
            this.infomsg = err.response.data.message;
            this.$toast.error("Oops, le chargement a échoué !");
        });
    }

    printDeSIActivityReport(){
        this.$toast.info("La création du rapport DeSI-Activités est en cours.");
        window.open(process.env.BASE_URL + "printDeSIActivityReport/?id=" + this.DeSIACTIVqId + "&talentsId=&metierList=")

    }


    mounted(){
        this.DeSIACTIVqId = (this.$route.query.id as string) || "";
        this.fetchData();
        console.log(this.DeSIACTIVqId)
    }
    
}
