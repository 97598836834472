































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import PaginatedViewer from "@/components/PaginatedViewer.vue"; 

axios.defaults.baseURL = process.env.BASE_URL;

interface QuestionScore {
  nameScore: string;
  valueScore: string;
  positionScore: string;
  weightScore: string;
}


interface Activity{
    activity: string;
    _id: string;
}

interface DeSIACTIVSecteur{
    secteur: string;
    _id: string;
}


interface RadioQuestions {
    question: string;
    activities: Array<Activity>;
    secteurs: Array<DeSIACTIVSecteur>;
    _id: string;
}

interface ContextQuestions {
    question: string;
    _id: string;
    responsesInList: boolean;
    responsesList: string;
    selectedlist: string;
    fieldType: string;
    fieldName: string;
    obligatory: boolean;
    defaultAnswer: string;
}

interface FilterCombination {
    socialProfil: string,
    realProfil: string,
}

interface PCD {
    
    id: string,
    pcdName: string,
    filterCombination: Array<FilterCombination>,
}

@Component({
  components: { PaginatedViewer}
})

export default class QuestionnaireSectActiv extends Vue {

    /************************** Configs *************************************/
    durations : any = [];
    autonomies : any = []; 

    currentValue : any = {};
    currentAge = -1;
    currentExperience = -1;
    currentLevel = "";

    durationFilters : any = [];
    autonomyFilters : any = [];

    durationId = '';
    autonomyId = '';

    getautonomyName(id){
        return this.autonomies.filter(e => e._id == id).map(e => e.autonomie)[0]; 
    }

    handleAddAutonomyFilter(){
        this.$bvModal.show('modal-autonomy');
        this.currentValue = {};
        this.currentExperience = -1;
        this.currentLevel = "";        
    }

    fetchAutonomyFilters(){
        axios
        .get("/api/autonomyFilter")
        .then(ans => {
            this.autonomyFilters = ans.data;
        })
    }

    closeModalAutonomyFilter(){
        this.$bvModal.hide('modal-autonomy');
        this.currentValue = {};
        this.currentExperience = -1;
        this.currentLevel = "";        
        this.autonomyId =  "";
    }

    updateAutonomyFilter(p){

        this.$bvModal.show('modal-autonomy');
        this.currentValue = {_id: p.autonomy, autonomie: this.getautonomyName(p.autonomy)};
        this.currentExperience = p.experience;
        this.currentLevel = p.level;  
        this.autonomyId =  p.id;  
    }

    addAutonomyFilter(){
        axios
        .post("/api/autonomyFilter", {}, {params: {id: this.autonomyId, autonomy: this.currentValue._id, experience: this.currentExperience, level: this.currentLevel}})
        .then(ans => {
            this.$toast.success("Filtre autonomie ajouté avec succés !");
            this.fetchAutonomyFilters();            
        })
        .catch()
        this.closeModalAutonomyFilter();        
    }

    removeAutonomyFilter(filter){
        axios
        .delete("/api/autonomyFilter", {params: {id: filter.id}})
        .then(ans => {
            this.$toast.success("Filtre autonomie supprimé avec succés !");
            this.fetchAutonomyFilters();
        })
        .catch(err => {
            this.$toast.error('Une erreur est survenue'); 
        });
    }
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    fetchPhrase() {
        axios.get('/api/change-phrase')
            .then(response => {
                console.log(response.data); // Output the response data
                this.var_phrase = response.data.phrase;
            })
    }

    changePhraseModal() {
        this.$bvModal.show('var-phrase-modal');
    }

    changePhrase() {
        const requestData = {
            phrase: this.var_phrase
        };
        axios.post('/api/change-phrase', requestData)

    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    getDurationName(id){
        return this.durations.filter(e => e._id == id).map(e => e.duree_etudes)[0];
    }

    fetch_durations(){
        return axios
        .get("/api/JobMatchingVariableData")
        .then(ans => {
            this.durations = ans.data.durations;
            this.autonomies = ans.data.autonomies;
        })
    }

    removeDurationFilter(filter){
        axios
        .delete("/api/durationFilter", {params: {id: filter.id}})
        .then(ans => {
            this.$toast.success("Filtre durée d'études supprimé avec succés !");
            this.fetchDuratioFilters();
        })
        .catch(err => {
            this.$toast.error('Une erreur est survenue'); 
        });
    }

    fetchDuratioFilters(){
        axios
        .get("/api/durationFilter")
        .then(ans => {
            this.durationFilters = ans.data;
        })
    }

    handleAddDurationFilter(){
        this.$bvModal.show('modal-duration');
        this.currentValue = {};
        this.currentAge = -1;
        this.currentExperience = -1;
        this.currentLevel = "";
    }

    closeModalDurationFilter(){
        this.$bvModal.hide('modal-duration');
        this.currentValue = {};
        this.currentAge = -1;
        this.currentExperience = -1;
        this.currentLevel = "";
        this.durationId = '';

    }

    updateDurationFilter(p){

        this.$bvModal.show('modal-duration');
        this.currentValue = {_id: p.duration, duree_etudes: this.getDurationName(p.duration)};
        this.currentAge = p.age;
        this.currentExperience = p.experience;
        this.currentLevel = p.level;        

        this.durationId = p.id;
    }

    addDurationFilter(){

        axios
        .post("/api/durationFilter", {}, {params: {id: this.durationId, duration: this.currentValue._id, age: this.currentAge, experience: this.currentExperience, level: this.currentLevel}})
        .then(ans => {
            this.$toast.success("Filtre durée d'études ajouté avec succés !");
            this.fetchDuratioFilters();            
        })
        .catch()
        this.closeModalDurationFilter();

    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    currentProfil: any = {id : '', profil: '', ascendance: {name: '', value: -1}, ouverture: {name: '', value: -1}};
    profils: Array<any> = [];
    
    handleAddProfilname(){
        this.$bvModal.show('modal-profil');
        this.currentProfil = {id : '', profil: '', ascendance: {name: '', value: -1}, ouverture: {name: '', value: -1}};
    }

    fetch_profils(){
        return axios
        .get("/api/socailRealProfils")
        .then(ans => {
            this.profils = ans.data;
        })
    }

    addProfil(){

        if (this.currentProfil.profil == "" && this.currentProfil.ascendance.name == '' && this.currentProfil.ouverture.name == ''){
            this.$toast.error("Veuillez remplir les champs necessaire avant de cliquer ajouter.");
        }
        else{
            axios
            .post("/api/socailRealProfils", {}, {params: {id: this.currentProfil.id, name: this.currentProfil.profil, ascendance: this.currentProfil.ascendance, ouverture: this.currentProfil.ouverture}})
            .then(ans => {
                this.$toast.success("Profil ajouté avec succés !");
                this.fetch_profils();
                this.fetch_PCDs();
            })
            .catch(err => {
                this.$toast.error("Nous rencontrons un probleme !");
            })
            this.closeModalProfil();
            this.fetch_profils();
        }

    }

    updateProfil(p){
        this.currentProfil = p;
        this.$bvModal.show('modal-profil');
    }

    removeProfil(p){
        //remove from database
        axios.delete("/api/socailRealProfils", {params: {id: p.id}})
        .then(ans => {
            this.fetch_profils();
            this.$toast.success('Profil supprimé');
        })
        .catch(err => {
        this.$toast.error('Une erreur est survenue'); 
        });
    }

    closeModalProfil(){
        this.$bvModal.hide('modal-profil');
        this.currentProfil = {id: '', profil: '', ascendance: {name: '', value: -1}, ouverture: {name: '', value: -1}};
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////
    pcdfilters: Array<PCD> = [];
    currentPCD: PCD = {
        id : "",
        pcdName : "",
        filterCombination : [],
    };
    currentSocialProfil: any = {id : '', profil: ''};
    currentRealProfil: any = {id : '', profil: ''};

    getProfilName(id){
        return this.profils.filter(e=> e.id == id).map(e => e.profil)[0];
    }

    removeCombinaison(currentPCD, row){

        this.currentPCD.filterCombination = this.currentPCD.filterCombination.filter(e => e.socialProfil != row.socialProfil || e.realProfil != row.realProfil);
    }
    
    handleAddPCDFilter(){
        this.$bvModal.show('modal-pcd');
        this.currentPCD = {
            id : "",
            pcdName : "",
            filterCombination : [],
        };
    }

    updatePCDFilter(p){
        this.$bvModal.show('modal-pcd');
        this.currentPCD = p;
    }

    closeModalPCD(){

        this.$bvModal.hide('modal-pcd');
        this.currentPCD = {
            id : "",
            pcdName : "",
            filterCombination : [],
        };        
    }

    fetch_PCDs(){
        return axios
        .get("/api/PCDFilters")
        .then(ans => {
            this.pcdfilters = ans.data;
        })
    }

    addFilterCombination(){

        var exist = false;
        if (this.currentSocialProfil.id == "" && this.currentRealProfil.id == ""){
            //this.$toast.error("Veuillez introduire");
        }else{
            this.currentPCD.filterCombination.forEach( e => {
                if (this.currentSocialProfil.id == e.socialProfil && this.currentRealProfil.id == e.realProfil){
                    this.$toast.error("Cette combinaison existe déjà !");
                    exist = true;
                }
            });
            if (exist == false){
               this.currentPCD.filterCombination.push({'socialProfil': this.currentSocialProfil.id, 'realProfil': this.currentRealProfil.id}); 
               this.currentSocialProfil = {id : '', profil: ''};
               this.currentRealProfil = {id : '', profil: ''};
            }
        }
    }

    addPCDFiltre(){

        if (this.currentPCD.pcdName == "" && this.currentPCD.filterCombination.length == 0){
            this.$toast.error("Veuillez remplir tous les champs avant de cliquer ajouter.");
        }
        else{
            axios
            .post("/api/PCDFilters", {}, {params: {id: this.currentPCD.id, pcdName: this.currentPCD.pcdName, filterCombination: this.currentPCD.filterCombination, combinationNBR: this.currentPCD.filterCombination.length}})
            .then(ans => {
                this.$toast.success("Filtre PCD ajouté avec succés !");
                this.fetch_PCDs();

            })
            .catch(err => {
                this.$toast.error("Nous rencontrons un problème !");
            })
            this.closeModalPCD();
        }
    }

    removePCDFilter(p){
        //remove from database
        axios.delete("/api/PCDFilters", {params: {id: p.id}})
        .then(ans => {
                this.$toast.success("Filtre PCD supprimé !");
                this.fetch_PCDs();
        })
        .catch(err => {
        this.$toast.error('Une erreur est survenue'); 
        });
        
    }
    /************************** SECTEURS *************************************/
    
    refMetiersecteurs = [];
    currentSecteur : DeSIACTIVSecteur =  {_id: '', secteur: ''};
    selectedReFMetierSecteurs : Array<string> = [];
    secteurs : Array<DeSIACTIVSecteur> = [];

    secteurMatchingDict = {};

    handleAddNewSecteur(){
        this.currentSecteur = {_id: '', secteur: ''};
        this.selectedReFMetierSecteurs = [];
        this.$bvModal.show('modal-4');
    }

    fetch_RefMetierSecteurs(){

        return axios.get("/api/get_DeSIACTIV_data", {params : { view: 'secteurs' }})
        .then(ans => {
            this.refMetiersecteurs = ans.data
        })
        .catch(console.log);     

    }

    setCurrentRefMetier(secteur){

        console.log(this.secteurMatchingDict);

        this.currentSecteur = secteur;
        this.selectedReFMetierSecteurs = this.secteurMatchingDict[secteur._id];
        this.$bvModal.show('modal-4');

    }

    get RefMetiersecteursOpts(){

        const defaultval: any[] = [{text: "Selectionner un secteur ou plusieurs...", value: '', disabled: true}];
        return defaultval.concat(this.secteurs.map(e => {
        return {
            text: e.secteur,
            value: e._id,
        }
        }))
    }

    saveSecteur(){

        if (this.currentSecteur.secteur == "" || this.selectedReFMetierSecteurs.length == 0){
            this.$toast.error("Veuillez introduire le champs secteur, et selectionner la liste des secteurs associes");
        }
        else{

            let sects: Array<string> = [];
            for (let i=0; i < this.selectedReFMetierSecteurs.length; i++){
                sects.push(this.selectedReFMetierSecteurs[i]);
            }

            axios.post("/api/get_DeSIACTIV_data", {}, {params : {view: 'DeSIACTIVsecteurs', id: this.currentSecteur._id, secteur: this.currentSecteur.secteur }})
            .then( ans => {
                let _id = ans.data;
                // save in table de correspondance DeSIACTI secteur -> RefMetier secteurs
                axios.post("/api/secteursMatching",  {}, {params: {refMetiers: sects, desiACTIV: _id }})
                .then(ans => {
                    this.fetch_secteurMatching();
                    this.$toast.success("sauvegarde reussie");
                })               
                this.fetch_RefMetierSecteurs();

            }).catch(err => {
                console.log(err)
                this.$toast.error(err.response.data);
            });

            this.selectedReFMetierSecteurs = [];
            this.$bvModal.hide('modal-4');

        }
    }

    fetch_secteurMatching(){

        return axios.get("/api/get_DeSIACTIV_data", {params : { view: 'secteursMatching' }})
        .then(ans => {

            for (let i=0; i < ans.data.length; i++){
                this.secteurMatchingDict[ans.data[i].desiACTIV] = ans.data[i].refMetiers;
            }
        })
        .catch(console.log);     

    }
    /************************** ACTIVITIES *************************************/

    /************************** SCORES *************************************/
    questionScores: Array<QuestionScore> = [];
    currentQuestionScore: QuestionScore = {nameScore: "", valueScore: "", positionScore: "", weightScore: ""};
    questionScoresUpdated = true;
    addORupdateScore = 'Ajouter';
    currentId = "";

    handleAddScore(){
        this.$bvModal.show('modal-1');
        this.addORupdateScore = 'Ajouter';
        this.currentQuestionScore = {nameScore: "", valueScore: "", positionScore: "", weightScore: ""};
    }

    addScore(){

        if (this.currentQuestionScore.nameScore != "" && this.currentQuestionScore.valueScore != "" && this.currentQuestionScore.positionScore != "" && this.currentQuestionScore.weightScore != ""){

            //add to database
            axios.post("/api/DeSIACTIVradioQuestionScore", {score: this.currentQuestionScore, id: this.currentId ,addORupdateScore: this.addORupdateScore})
            .then(ans => {

                this.questionScoresUpdated = true;                
                this.fetch_RadioQuestionScore();
                this.currentQuestionScore = {nameScore: "", valueScore: "", positionScore: "", weightScore: ""};   
                this.$toast.success(ans.data);
            })
            .catch(err => {
                console.log(err)
                this.$toast.error(err.response.data);
            });

            this.$bvModal.hide('modal-1');            
        }else{
            this.$toast.error("Veuillez remplir les champs necessaire avant de cliquer ajouter.");
        }

        this.currentId = "";

    }

    updateScore(score){

        this.addORupdateScore = 'Modifier';
        this.currentId = score['id']
        this.$bvModal.show('modal-1');

        this.currentQuestionScore = {nameScore: score.nameScore, valueScore: score.valueScore, positionScore: score.positionScore, weightScore: score.weightScore};
        if (score.valueScore == '0'){

            this.currentQuestionScore.valueScore = '0';

        }

        if (score.weightScore == '0'){

            this.currentQuestionScore.weightScore = '0';

        }
    }

    removeScore(score){        
        //remove from database
        axios.delete("/api/DeSIACTIVradioQuestionScore", {params: {nameScore: score.nameScore, positionScore: score.positionScore}})
        .then(ans => {
            this.questionScores = this.questionScores.filter(e => (e.nameScore != score.nameScore));
            this.questionScoresUpdated = true;
        })
        .catch(console.log);
    }

    fetch_RadioQuestionScore(){
        
        return axios.get("/api/DeSIACTIVradioQuestionScore")
        .then(ans => {
            this.questionScores = ans.data
        })
        .catch(console.log); 
    }

    /************************** CONTEXT QUESTIONS *************************************/

    contextQuestions: Array<ContextQuestions> = [];
    currentContextQuestion : ContextQuestions = {    
        question: "",
        responsesInList: false,
        responsesList: "",
        selectedlist: "",
        _id: "",
        fieldType: "",
        fieldName: "",

        obligatory: false,
        defaultAnswer: "",
    };
    ResponsesListValue = "";
    champSupplementaire = false;

    typeOpts = [
        { value: null, text: "Veuillez sélectionner une option", disabled:true },
        { value: "int", text: "Entier" },
        { value: "string", text: "Chaine de caracteres" },
        { value: "date", text: "Date" },
    ];


    listsOpts = [
        { value: null, text: "Veuillez sélectionner une option", disabled:true },
        { value: "DeSIACTIVsecteur", text: "Secteurs d'activité" },
        { value: "postes", text: "Postes" },
        { value: "diplomes", text: "Diplôme" }
        ];
    
    closeModal3(){
        this.currentContextQuestion = {    
            question: "",
            responsesInList: false,
            responsesList: "",
            selectedlist: "",
            _id: "",
            fieldType: "",
            fieldName: "",
            obligatory: false,
            defaultAnswer: "",
            };        

        this.$bvModal.hide('modal-3');
    }

    updateContextQuestion(q){
        console.log(q)
        this.$bvModal.show('modal-3');
        this.currentContextQuestion = {    
            question: q.question,
            responsesInList: q.responsesInList,
            responsesList: q.responsesList,
            selectedlist: q.selectedlist,
            _id: q._id,
            fieldType: q.fieldType,
            fieldName: q.fieldName,
            obligatory: q.obligatory,
            defaultAnswer: q.response,
        };

        if (q.responsesInList == 'false'){
            this.currentContextQuestion.responsesInList = false;
        }else{
            this.currentContextQuestion.responsesInList = true;
        }

        if (q.obligatory == 'false'){
            this.currentContextQuestion.obligatory = false;
        }else{
            this.currentContextQuestion.obligatory = true;
        }

        this.champSupplementaire = q.fieldType != "";
        this.ResponsesListValue = this.currentContextQuestion.responsesList;
    }

    addContextQuestion(){

        if (this.currentContextQuestion.responsesInList){
            this.currentContextQuestion.responsesList = this.ResponsesListValue;
        }

        axios.post('/api/DeSIACTIVcontextQuestion', {}, {params: {
                question: this.currentContextQuestion.question,
                responsesInList: this.currentContextQuestion.responsesInList,
                responsesList: this.currentContextQuestion.responsesList,
                selectedlist: this.currentContextQuestion.selectedlist,
                fieldType: this.currentContextQuestion.fieldType,
                fieldName: this.currentContextQuestion.fieldName,
                _id : this.currentContextQuestion._id,
                obligatory: this.currentContextQuestion.obligatory,
                response: this.currentContextQuestion.defaultAnswer,
            }})
        .then(ans => {
            this.$toast.success("Question mise a jour");
            this.fetch_ContextQuestions();

        }).catch(err => {
            console.log(err);
        });
  
        this.currentContextQuestion = {    
            question: "",
            responsesInList: false,
            responsesList: "",
            selectedlist : "",
            _id: "",
            fieldType: "",
            fieldName: "",
            obligatory: false,
            defaultAnswer: "",
        }; 
        this.champSupplementaire = false;
        
        this.$bvModal.hide('modal-3');

    }

    handleAddContextualQuestion(){
        this.$bvModal.show('modal-3');
        this.currentContextQuestion = {    
            question: "",
            responsesInList: false,
            responsesList: "",
            selectedlist: "",
            _id: "",
            fieldType: "",
            fieldName: "",

            obligatory: false,
            defaultAnswer: "",
    };        
    }

    fetch_ContextQuestions(){
        
        return axios.get("/api/get_DeSIACTIV_data", {params : { view: 'DeSIACTIVcontextQuestions' }})
        .then(ans => {
            this.contextQuestions = ans.data
        })
        .catch(console.log);     
    }

    /************************** RADIO QUESTIONS *************************************/
    
    radioQuestions: Array<RadioQuestions> = [];
    DeSIACTIVsecteurs: Array<DeSIACTIVSecteur> = [];
    DeSIACTIVactivities: Array<Activity> = [];    
        
    currentRadioQuestion: RadioQuestions = {    
        question: "",
        activities: [],
        secteurs: [],
        _id: "",
    };

    selectedSecteurs: Array<string> = [];
    selectedActivities: Array<string> = [];

    handleAddRadioQuestion(){
        this.$bvModal.show('modal-2');
        
    }

    closeModal2(){
        this.currentRadioQuestion= {    
            question: "",
            activities: [],
            secteurs: [],
            _id: "",
        };        
        this.$bvModal.hide('modal-2');
    }

    addRadioQuestion(){

        const d1: Array<DeSIACTIVSecteur>= []
        for (let i = 0; i < this.selectedSecteurs.length; i++){
            const sec: DeSIACTIVSecteur = this.DeSIACTIVsecteurs.filter(s => s._id == this.selectedSecteurs[i])[0];
            d1.push({
                _id: this.selectedSecteurs[i],
                secteur: sec.secteur
            })
        }
        this.currentRadioQuestion.secteurs = d1;

        ///////////////////////
        const d2: Array<Activity> = []
        for (let i = 0; i < this.selectedActivities.length; i++){
            const act: Activity = this.DeSIACTIVactivities.filter( a => a._id == this.selectedActivities[i])[0];
            console.log("selectedactivitie i o")
            console.log(this.selectedActivities[i])
            d2.push({
                _id: this.selectedActivities[i],
                activity: act.activity
            })
        }
        this.currentRadioQuestion.activities = d2;
        
        //////////////////////
        axios.post('/api/DeSIACTIVradioQuestion', {
                            question: this.currentRadioQuestion.question, 
                            secteurs: this.currentRadioQuestion.secteurs,
                            activities: this.currentRadioQuestion.activities,
                            _id: this.currentRadioQuestion._id
        })
        .then(ans => {
            this.$toast.success("Questions mises a jour");
            this.fetch_RadioQuestions();

        }).catch(err => {
            console.log(err);
        });
  
        this.currentRadioQuestion= {    
            question: "",
            activities: [],
            secteurs: [],
            _id: "",
        }; 
        
        this.$bvModal.hide('modal-2');

    }
    
    fetch_RadioQuestions(){
        
        return axios.get("/api/get_DeSIACTIV_data", {params : { view: 'DeSIACTIVRadioQuestions' }})
        .then(ans => {
            this.radioQuestions = ans.data
            console.log(ans)
        })
        .catch(console.log);     
    }

    fetch_DeSIACTIVactivities(){
        return axios.get("/api/get_DeSIACTIV_data", {params : { view: 'DeSIACTIVactivities' }})
        .then(ans => {
            this.DeSIACTIVactivities = ans.data;
            })
        .catch(console.log);             
    }

    fetch_DeSIACTIVsecteurs(){

        axios.get("/api/get_DeSIACTIV_data", {params : { view: 'secteurs' }})
        .then(ans => {
            console.log(ans)
            this.secteurs = ans.data;
        })
        .catch(console.log); 

        return axios.get("/api/get_DeSIACTIV_data", {params : { view: 'DeSIACTIVsecteurs' }})
        .then(ans => {
            this.DeSIACTIVsecteurs = ans.data;
        })
        .catch(console.log);             
    }

    get secteursOpts(){

        const defaultval: any[] = [{text: "Selectionner un secteur ou plusieurs...", value: '', disabled: true}];
        return defaultval.concat(this.DeSIACTIVsecteurs.map(e => {
        return {
            text: e.secteur,
            value: e._id,
        }
        }))
    }

    get activitiesOpts(){

        const defaultval: any[] = [{text: "Selectionner une activité ou plusieurs...", value: '', disabled: true}];
        return defaultval.concat(this.DeSIACTIVactivities.map(e => {
        return {
            text: e.activity,
            value: e._id,
        }
        }))

    }

    updateRadioQuestion(q){
        this.selectedSecteurs = [];
        this.selectedActivities = [];
        this.$bvModal.show('modal-2');
        this.currentRadioQuestion = q;  
        if (q.secteurs){
            q.secteurs.map(secteur => this.selectedSecteurs.push(secteur._id));
        }

        if (q.activities){
            q.activities.map(activity => this.selectedActivities.push(activity._id));
        }
    }


    removeRadioQuestion(q){
        console.log(q);
        return axios.delete("/api/get_DeSIACTIV_data", {params : { view: 'DeSIACTIVRadioQuestions', id : q._id}})
        .then(ans => {
            this.fetch_RadioQuestions;
            this.$toast.success("Question supprimee !");
        })
        .catch(console.log);            
    }

    /*********************************************************************************************/
    mounted(){
        this.fetch_RadioQuestionScore();
        this.fetch_RadioQuestions();
        this.fetch_DeSIACTIVsecteurs();
        this.fetch_DeSIACTIVactivities();
        this.fetch_ContextQuestions();
        this.fetch_RefMetierSecteurs();
        this.fetch_secteurMatching();
        this.fetch_profils();
        this.fetch_PCDs();
        this.fetch_durations();
        this.fetchDuratioFilters();
        this.fetchAutonomyFilters();
    }

}

