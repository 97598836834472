



























































































































































































































































































































































































import { Component, Vue, Prop, Watch  } from "vue-property-decorator";
import { mapGetters } from "vuex";
import axios from "axios";
import { Plotly } from 'vue-plotly';

import _, { forEach } from "lodash";


axios.defaults.baseURL = process.env.BASE_URL;

@Component({
  computed: mapGetters(["getUser"]),
  components: { Plotly}
})


export default class ScoresComponent extends Vue{
    
    @Prop()
    DeSIACTIVqId: any;

    @Prop()
    handleJobs: any;

    @Prop()
    jobs: any;

    @Prop()
    ascendance_social: any;

    @Prop()
    ouverture_social: any;

    @Prop()
    ascendance_real: any;

    @Prop()
    ouverture_real: any;

    @Prop()
    var_phrase: any;

    getUser: any;
    referrer: any = {};
    baseurl = process.env.BASE_URL;

    scores: any = {};

    activities_R: Array<number> = [];
    activities_Theta: Array<String> = [];

    secteurs_R: Array<number> = [];
    secteurs_Theta: Array<String> = [];

    sortedSecteurs: any = {};
    sortedActivities: any = {};

    autonomies: any = [];
    durations: any = [];
    handicap: any = [];
    secteurs_List: any[] = [];
    families_List: any[] = [];

    currentHandicap: any[] = [];
    currentPCD: any[] = [];
    currentAutonomy: any[] = [];
    currentDuration: any[] = [];
    currentSecteur: any[] = [];
    currentSitension: any = false;
    currentFamily: any[] = [];
    new_data: any = [];


    duration_data: any = [];
    autonomy_data: any = [];
    secteur_data: any = [];
    pcd_data: any = [];
    handicap_data: any = [];
    siTension_data: any = [];
    family_data: any = [];
    webAddresses: any = [];
    othersWebAddresses: any = [];
    InitialRefMetier: any = [];


    clickedMetier: string = "";
    new_data_updated: boolean = false;
    NoNewDataSelected: boolean = true;
    
    PCD: Array<String> = [];
    profils: any = [];

    get handicap_opts(){
        return this.handicap.map( e => e.handicap);
     }


    get autonomies_opts(){
        
        return this.autonomies.map( e => e.autonomie);
    }

    get durations_opts(){

        return this.durations.map( e => e.duree_etudes)

    }


    get googleExist(){
        return this.webAddresses.filter(e => e.description.toLowerCase() == 'google').length > 0; 
    }

    get mapsExist(){
        return this.webAddresses.filter(e => e.description.toLowerCase() == 'maps').length > 0; 
    }


    get othersExist(){
        return this.webAddresses.filter(e => (e.description.toLowerCase() != 'maps' && e.description.toLowerCase() != 'google')).length > 0; 
    }

    othersSearchClicked(metier){
        this.$bvModal.show("modal-searchMetier");
        this.clickedMetier = metier;
        this.othersWebAddresses = this.webAddresses.filter(e => (e.description.toLowerCase() != 'maps' && e.description.toLowerCase() != 'google'));
    }


    googleSearch(metier){
        var address = this.webAddresses.filter(e => e.description.toLowerCase() == 'google')[0];
        window.open(address.webAdderess + metier);
    }

    googleMapsSearch(metier){
        var address = this.webAddresses.filter(e => e.description.toLowerCase() == 'maps')[0];
        window.open(address.webAdderess + metier);
    }

    othersSearch(metier){
        var address = this.webAddresses.filter(e => (e.description.toLowerCase() != 'maps' && e.description.toLowerCase() != 'google'))
        window.open(metier.webAdderess + encodeURIComponent(this.clickedMetier));
    }


    //////////////////////////////////////////////////////////////////////////////////

    @Watch("currentDuration")
    durationChanged() {
        this.durationSelected();
    }

    durationSelected(){

        if (this.currentDuration.length > 0){
            var data_var: any = [];

            this.jobs.forEach(job => {
                this.currentDuration.forEach(duration => {
                    if (job.duree_etudes.toLowerCase() == duration.toLowerCase()){
                        data_var.push(job);
                    }})
            });

            this.duration_data = data_var;             
        }else{
            this.duration_data = this.jobs;   
        }

        this.new_data_updated = true;
    }

    //////////////////////////////////////////////////////////////////////////////////

    @Watch("currentAutonomy")
    autonomyChanged() {
        this.autonomySelected();
    }

    autonomySelected(){

        if (this.currentAutonomy.length > 0){
            var data_var: any = [];
            this.jobs.forEach(job => {
                this.currentAutonomy.forEach(autonomy => {
                    if (job.autonomie == autonomy){
                        data_var.push(job);
                    }})
            });
            this.autonomy_data = data_var;            
        }else{
            this.autonomy_data = this.jobs;
        }

        this.new_data_updated = true;
    }   
    
    //////////////////////////////////////////////////////////////////////////////////

    @Watch("currentSecteur")
    secteurChanged() {
        this.secteurSelected();

    }

    secteurSelected(){

        if (this.currentSecteur.length > 0){
            var data_var: any = [];
            this.jobs.forEach(job => {
                this.currentSecteur.forEach(sectItem => {

                    if (job.secteurs == sectItem.secteur ){
                        data_var.push(job);
                    }           
                });
            });
            this.secteur_data = data_var;
        }else{
            this.secteur_data = this.jobs;
        }
        this.new_data_updated = true; 
    }  

    @Watch("secteur_data")
    secteurDataChanged(){

        var familiesListVar = this.secteur_data.map(j => j.famille_metier)
                            .filter((value, index, self) => self.indexOf(value) === index);
        
        if (this.secteur_data.length == this.jobs.length){
            this.currentFamily = []
        }
        else{
            if (this.currentFamily.length == 0){
                this.currentFamily = familiesListVar;
            }
            else{
                var notSelected = _.difference(this.families_List, this.currentFamily);
                this.currentFamily = _.difference(familiesListVar, notSelected);                
            }

        }

        this.families_List = familiesListVar;
    }

    ///////////////////////////////////////////////////////////////////////////////////
    @Watch("currentFamily")
    familyChanged(){
        this.familySelected();
    }

    familySelected(){
        
        if (this.currentFamily.length > 0){
            var data_var: any = [];
            this.jobs.forEach(job => {
                this.currentFamily.forEach(famItem => {

                    if (job.famille_metier == famItem ){
                        data_var.push(job);
                    }           
                });
            });
            this.family_data = data_var;
        }else{
            this.family_data = this.jobs;
        }
        this.new_data_updated = true; 

    }
        
    //////////////////////////////////////////////////////////////////////////////////

    @Watch("currentPCD")
    pcdChanged() {
        this.pcdSelected();
    }

    pcdSelected(){

        var data_var: any = [];
        if (this.currentPCD.length > 0){
            this.jobs.forEach(job => {
                this.currentPCD.forEach(pcdItem => {
                    if (job.pcd == pcdItem){
                        data_var.push(job);
                    }})
            });
            this.pcd_data = data_var; 
        }else{
            this.pcd_data = this.jobs;
        }

        this.new_data_updated = true;
    }   

    //////////////////////////////////////////////////////////////////////////////////

    @Watch("currentHandicap")
    handicapChanged() {
        this.handicapSelected();
    }

    handicapSelected(){

        var data_var: any = [];
        if (this.currentHandicap.length > 0){
            this.jobs.forEach(job => {
                this.currentHandicap.forEach(handicapItem => {
                    if (job.handicap == handicapItem){
                        data_var.push(job);
                    }})
            });
            this.handicap_data = data_var;            
        }else{
            this.handicap_data = this.jobs;
        }

        this.new_data_updated = true;
    }   

    ///////////////////////////////////////////////////////////////////////////////////

    @Watch("currentSitension")
    SitensionChanged() {
        this.SitensionSelected();
    }

    SitensionSelected(){

        var data_var: any = [];

        this.jobs.forEach(job => {
            if (this.currentSitension == true && job.si_tension != null){
                data_var.push(job);
            }else if (this.currentSitension == false && job.si_tension == null){
                data_var.push(job);
            }
        });

        this.siTension_data = data_var;
        this.new_data_updated = true;

    } 


    ///////////////////////////////////////////////////////////////////////////////////

    @Watch("new_data_updated")
    newDataChanged() {
        
        if (this.new_data_updated){
            this.new_data = [];

            if (this.currentDuration.length > 0 || this.currentAutonomy.length > 0 || this.currentPCD.length > 0 || this.currentSecteur.length >0 || this.currentFamily.length > 0){
                this.new_data = _.intersection(this.duration_data,
                                                this.autonomy_data,
                                                this.secteur_data,
                                                this.pcd_data,
                                                this.family_data
                                                );
            }
            //filtres complementaires: ajouter leurs resultats aux premieres selections
            if (this.currentHandicap.length > 0 && this.currentSitension == true){
                this.new_data = _.union(this.new_data, _.union(this.handicap_data, this.siTension_data));
            }
            else {
                if (this.currentHandicap.length > 0){
                        this.new_data = _.union(this.new_data, this.handicap_data);
                }

                if (this.currentSitension == true){
                    this.new_data = _.union(this.new_data, this.siTension_data);
                }                
            }

            if (this.currentDuration.length == 0 && this.currentAutonomy.length == 0 && this.currentPCD.length == 0 && this.currentSecteur.length == 0 && this.currentHandicap.length == 0 && this.currentSitension == false && this.currentFamily.length == 0){
                this.new_data = this.jobs;
            }

            this.new_data = _.sortBy(this.new_data, [e=>1-e['percent']]);
            if (this.new_data.length){
                //this.new_data = this.siTension_data;
                this.NoNewDataSelected = false;
            }
            else{
                this.NoNewDataSelected = true;
            }

            if (this.new_data.length > 50){
                this.new_data = this.new_data.slice(0, 50);
            }

            this.$store
            .dispatch("setmetierList", this.new_data.map(e => e.id))
            .then(() => {
            })
            .catch();

            this.new_data_updated = false;
        }

    }

    levelResponse : string = "";
    ageResponse : number = 0;
    experienceResponse : number = 0;

    fetchData() {

        axios
        .get("/api/depouillateDeSIACTIV", {params: {id: this.DeSIACTIVqId}})
        .then(ans => {
            this.scores = ans.data;

            this.$store
            .dispatch("setDeSIActivQuestionnaireResponded", true)
            .then(() => {
            })
            .catch();


            for (let key in this.scores.activities_values) {
                if (this.scores.activities_values[key]['percent'] > 0){
                    this.activities_R.push(this.scores.activities_values[key]['percent'] * 100);
                    this.activities_Theta.push(this.scores.activities_values[key]['activity']);
                }
                //if (this.activities_Theta.length > 20 ){
                 //   break;
               // }
            }

            for (let key in this.scores.secteurs_values) {
                if (this.scores.secteurs_values[key]['percent'] > 0){
                    this.secteurs_R.push(this.scores.secteurs_values[key]['percent'] * 100);
                    this.secteurs_Theta.push(this.scores.secteurs_values[key]['secteur']);                    
                }
                //if (this.secteurs_Theta.length > 20 ){
                 //   break;
                //}

            }
            
            this.sortedSecteurs = _.sortBy(this.scores.secteurs_values, [e=>1-e['percent'], e => e['secteur']]);
            this.sortedActivities = _.sortBy(this.scores.activities_values, [e=>1-e['percent'], e => e['activity']]);

            axios.get("/api/get_DeSIACTIV_data", {params : { view: 'DeSIACTIVsecteurs' }})
            .then(ans => {

                let initialSecteurs: any = [];
                for (let i = 0 ; i < 3 ; i++){
                    if (i == 0 || i == 1){
                        initialSecteurs.push(ans.data.filter(e => e.secteur == this.sortedSecteurs[i]['secteur'])[0]._id);
                        
                    }
                    else if (i == 2 && this.sortedSecteurs[i]['percent'] >= 0.75){
                        initialSecteurs.push(this.sortedSecteurs[i]['secteur']);
                    }
                }

                axios.get("/api/get_DeSIACTIV_data", {params : { view: 'secteurs' }})
                .then(ans => {

                    ans.data.map( e  => {
                        this.secteurs_List.push(e) 
                        });

                    this.families_List = this.jobs.map(j => j.famille_metier)
                        .filter((value, index, self) => self.indexOf(value) === index)
                })

                axios.get("/api/get_DeSIACTIV_data", {params : { view: 'secteursMatching' }})
                .then(ans => {
                    initialSecteurs.forEach(e=> {
                        this.InitialRefMetier = _.union(this.InitialRefMetier, ans.data.filter(a => a.desiACTIV == e).map(b => b.refMetiers)[0]);
                    })

                    this.InitialRefMetier.forEach(i => {                 
                            this.currentSecteur.push(this.secteurs_List.filter(e => i == e._id)[0]);
                        })                    
                })
            })
        })
        .catch(err => {
            if (err.response.status == 404){
                this.$toast.info(err.response.data);
            }
            else{
                this.$toast.error("Oops, le chargement a échoué !");
            }
        });
    
        if (this.handleJobs){

            this.new_data = this.jobs;
            this.new_data = _.sortBy(this.new_data, [e=>1-e['percent']]);
            if (this.new_data.length > 50){
                this.new_data = this.new_data.slice(0, 50);
            }

            this.duration_data = this.jobs;
            this.autonomy_data = this.jobs;
            this.secteur_data = this.jobs;
            this.pcd_data = this.jobs;
            this.handicap_data = this.jobs;
            this.siTension_data = this.jobs;
            this.family_data = this.jobs;

            axios.get("/api/PCDFilters")
            .then(ans => {
                this.PCD = ans.data.map(profil=> profil.pcdName);
            })

            axios
            .get("/api/JobMatchingVariableData")
            .then(ans =>{
                    console.log(ans.data);
                    this.durations = ans.data.durations
                    this.autonomies = ans.data.autonomies                
            })
         
            axios.get("/api/get_DeSIACTIV_data", {params : { view: 'handicap' }})
            .then(ans => {
                this.handicap = ans.data;
            })

            axios.get("/api/webAddressMetier")
            .then(ans => {
                this.webAddresses = ans.data;
            })

            axios
            .get("/api/DeSIACTIVanswers", {params: {id: this.DeSIACTIVqId}})
            .then(ans => {

                if( ans.data.selectAll){
                    this.currentDuration = this.durations.map( e => e.duree_etudes);
                    this.currentAutonomy = this.autonomies.map( e => e.autonomie);
                }
                else {

                    ans.data.currentDurationFilters.forEach(d => {
                        this.currentDuration.push(
                            this.durations.filter(e => e._id == d).map( e => e.duree_etudes)[0]
                        );
                    })
                    ans.data.currentAutonomyFilters.forEach(d => {
                        this.currentAutonomy.push(
                            this.autonomies.filter(e => e._id == d).map( e => e.autonomie)[0]
                        );
                    })


                    for (let i = 0 ; i < 3 ; i++){
                        if (this.sortedActivities[i]["activity"] == "Réaliser soi-même" && this.sortedActivities[i]["percent"] >= 0.75){
                            this.currentAutonomy.push(
                                this.autonomies.filter(e => e._id == "641987d460a539c0384dd051").map( e => e.autonomie)[0]
                            );
                            break;
                        }
                    }

                }
                
            })


        }
    }


    getJobInfos(i){
        
        var str =  'Métier : ' + this.new_data[i]['metier'] + '\n' +
                'Secteur : ' + this.new_data[i]['secteurs'] +
                ' ( Famille : ' + this.new_data[i]['famille_metier'] + ' )\n' +
                "Durée d'études :  "+ this.new_data[i]['duree_etudes'] +
                ', Autonomie : ' + this.new_data[i]['autonomie'] + '\n' +
                'PCD : ' + this.new_data[i]['pcd'];

        if (this.new_data[i]['si_tension']){
            str += '\nCaractéristiques : Métier en tension';
        }

        if (this.new_data[i]['handicap']){
            str += '\nHandicaps : ' + this.new_data[i]['handicap'];
        }

        return str;
    }

    getProfil(asc, ouv, type){
                
        asc = asc * 100;
        ouv = ouv * 100;
        
        axios.get("/api/socailRealProfils")
        .then(ans => {
            
            var str: string = '';
            this.profils = ans.data;
            this.profils.forEach(profil => {
                
                if (profil.ascendance.name == "Asc < "){

                    if (profil.ouverture.name == "Ouv < "){
                        if (asc < profil.ascendance.value && ouv < profil.ouverture.value){
                            str = profil.profil;
                        }
                    }
                    else if (profil.ouverture.name == "Ouv >= "){
                        if (asc < profil.ascendance.value && ouv >= profil.ouverture.value){
                            str = profil.profil;
                        }
                    }
                    if (profil.ouverture.name == "Ouv > "){
                        if (asc < profil.ascendance.value && ouv > profil.ouverture.value){
                            str = profil.profil;
                        }
                    }
                }
                else if (profil.ascendance.name == "Asc >= "){
                    if (profil.ouverture.name == "Ouv < "){
                        if (asc >= profil.ascendance.value && ouv < profil.ouverture.value){
                            str = profil.profil;
                        }
                    }
                    else if (profil.ouverture.name == "Ouv >= "){
                        if (asc >= profil.ascendance.value && ouv >= profil.ouverture.value){
                            str = profil.profil;
                        }
                    }
                    if (profil.ouverture.name == "Ouv > "){
                        if (asc >= profil.ascendance.value && ouv > profil.ouverture.value){
                            str = profil.profil;
                        }
                    }
                }
                else if (profil.ascendance.name == "Asc > "){
                    if (profil.ouverture.name == "Ouv < "){
                        if (asc > profil.ascendance.value && ouv < profil.ouverture.value){
                            str = profil.profil;
                        }
                    }
                    else if (profil.ouverture.name == "Ouv >= "){
                        if (asc > profil.ascendance.value && ouv >= profil.ouverture.value){
                            str = profil.profil;
                        }
                    }
                    if (profil.ouverture.name == "Ouv > "){
                        if (asc > profil.ascendance.value && ouv > profil.ouverture.value){
                            str = profil.profil;
                        }
                    }
                }
            })
            if (type == 'social'){
                this.socialProfil = str;
            }
            else{
                this.realProfil = str;
            }
        })
    }

    socialProfil: string = '';
    realProfil: string = '';

    getInitialPCDFilter(){

        this.getProfil(this.ascendance_social, this.ouverture_social, 'social');
        this.getProfil(this.ascendance_real, this.ouverture_real, 'real');        
        var interval = setInterval(() => {
            if( this.socialProfil != "" && this.realProfil != ""){
                
                clearInterval(interval);
                
                var soc = this.profils.filter(e => e.profil == this.socialProfil).map(e => e.id)[0];
                var real = this.profils.filter(e => e.profil == this.realProfil).map(e => e.id)[0];

                axios.get("/api/PCDFilters")
                .then(ans => {
                    var arr: Array<String> = [];
                    ans.data.filter(profil=> {
                        if (profil.filterCombination.filter(e => e.realProfil == real && e.socialProfil == soc).length){
                            arr.push(profil.pcdName);
                        }
                    });
                    this.currentPCD = arr;
                })

            }
        }, 100);

    }


    mounted(){

        this.fetchData();        
        this.getInitialPCDFilter();

    }
    
}
